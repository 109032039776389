import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { ProgressBar, Button } from 'react-bootstrap';
import { createSearchParams, useNavigate, useLocation } from 'react-router-dom';
// import { QuestionData } from '../assets/data/questiondata';
import Footer from './footer';
import Adfit from "./Adfit";
import "../assets/fonts/font.css"
import ReactDOM from 'react-dom'
import axios from 'axios';
import Swal from "sweetalert2";
import KakaoShareBtn from '../component/KakaoShareBtn';
import profile_img from '../assets/main_image.png';
import Modal from 'react-modal';

//--------------------점수 이모지--------------------//
import ScoreImg1 from '../assets/score_1.png';
import ScoreImg2 from '../assets/score_2.png';
import ScoreImg3 from '../assets/score_3.png';
import ScoreImg4 from '../assets/score_4.png';
import ScoreImg5 from '../assets/score_5.png';
//-----------------------------------------------------//

//--------------------다른 테스트 썸네일--------------------//
import TestMZOimg from '../assets/mz-title.PNG';
import TestRRSimg from '../assets/rrs-title2.png';
import TestNYRimg from '../assets/nyr-title.png';
import TestKTAimg from '../assets/kta-title.png';
import TestPREimg from '../assets/pre-title.PNG'
//-----------------------------------------------------//

import {
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";

Modal.setAppElement('#root');
document.cookie = "myCookie=test; SameSite=None; Secure";

const Result = () => {

  //---------------------URL 기반 UUID 추출----------------------//
  const location = useLocation();
  const pathname = location.pathname;
  const UUID = pathname.substring(pathname.indexOf("/result/") + "/result/".length);
  const shareURL = `https://dreambook.click/result/${UUID}`;
  const API_URL = 'https://vjt159l0hb.execute-api.ap-northeast-2.amazonaws.com/prod_feedback/dreambook';
  const TR_API_URL = 'https://vjt159l0hb.execute-api.ap-northeast-2.amazonaws.com/prod_feedback/dreambook_translate';

  const FEEDBACK_API_URL = 'https://vjt159l0hb.execute-api.ap-northeast-2.amazonaws.com/prod_feedback/dreambook_feedback';
  //-----------------------------------------------------------//

  const [chatGPTresponse, setChatGPTresponse] = useState(null);
  const isChatGPTresponse = useRef(false);


  // 사용자 입력 피드백
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');


  const showFeedback = () => {
    return (loading ?
      <div>
        <h4 style={{ textAlign: "center" }}><font color="#FFFFFF">피드백 참여 완료!</font></h4><br />
      </div >
      :
      <div>
        <h4 style={{ textAlign: "center" }}><font color="#FFFFFF">AI 꿈풀이 서비스에 만족하시나요?</font></h4><br />
        <FeedbackContainer>
          <img src={ScoreImg1} style={{ marginRight: "10px" }} width={50} onClick={() => { handleButtonClick(1) }} /><br />
          <img src={ScoreImg2} style={{ marginRight: "10px" }} width={50} onClick={() => { handleButtonClick(2) }} /><br />
          <img src={ScoreImg3} style={{ marginRight: "10px" }} width={50} onClick={() => { handleButtonClick(3) }} /><br />
          <img src={ScoreImg4} style={{ marginRight: "10px" }} width={50} onClick={() => { handleButtonClick(4) }} /><br />
          <img src={ScoreImg5} style={{ marginRight: "10px" }} width={50} onClick={() => { handleButtonClick(5) }} /><br />
        </FeedbackContainer>
      </div>
    )
  }

  const handleButtonClick = async (feedbackscore) => {
    console.log(`피드백 점수 : ${feedbackscore}`);
    const { value: feedbacktext } = await Swal.fire({
      title: '전하고 싶은 의견이 있다면?',
      html: "주신 의견은 한글자도 빼놓지 않고 <br>꼼꼼히 읽어볼게요 :)",
      input: 'text',
      inputPlaceholder: '예시 : 꿈풀이가 Fun하지 않고 너무 뻔해요',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonText: '제출',
      cancelButtonText: '취소',
    });

    if (feedbacktext) {
      const data = {
        UUID: UUID,
        chatGPTresponse: chatGPTresponse,
        feedbackscore: feedbackscore,
        feedbacktext: feedbacktext,
      };

      // 사용자가 텍스트를 입력하고 확인 버튼을 눌렀을 때 처리할 로직
      axios
        .post(FEEDBACK_API_URL, data)
        .then((response) => {
          console.log(`Score : ${response.data.feedbackscore}`);
          console.log(`Text : ${response.data.feedbacktext}`);
        });
      Swal.fire({
        title: '꼼꼼히 읽어볼게요! <br>의견 감사드립니다 :)',
        icon: 'success',
        confirmButtonText: '확인',
      });
      setLoading(true);
    } else {
      // 사용자가 취소 버튼을 눌렀을 때 처리할 로직
      console.log('사용자가 취소를 눌렀습니다.');
    }
  };


  useEffect(() => {
    console.log("show response");

    let isMounted = true;
    const fetchData = async () => {
      const response = await axios.get(API_URL, {
        params: {
          uuid: UUID,
        }
      })
        .then(response => {
          if (isMounted) {
            console.log(`response: ${response.data.chatGPTresponse}`);
            setChatGPTresponse(response.data.chatGPTresponse);
            isChatGPTresponse.current = true;
          }
        })
        .catch(error => {
          console.error(error);
        })
    };
    fetchData();
    return () => {
      isMounted = false;
    }
  }, []);

  const parsing_response = (str) => {
    console.log(`beforeparse: ${str}`);
    let st1 = str.replace(/ {2,3}/g, '$');
    let st2 = st1.replace(/[^\S\n]/g, '');
    let st3 = st2.replace(/\$/g, ' ');
    let parsedObj = st3;
    return parsedObj;
  };

  const showResponse = () => {
    console.log("isChatGPT??")
    console.log(isChatGPTresponse);
    console.log(chatGPTresponse);

    if (isChatGPTresponse.current) {
      const processedResponse = parsing_response(chatGPTresponse);
      console.log(processedResponse);
      return (
        <div>
          <div class="chat ch1">
            <img class='icon' src={profile_img} alt="profile_img" />
            <div class="textbox">
              <QuestionText>당신의 꿈해몽 결과를 상세하게 알려드릴게요!</QuestionText>
            </div>
          </div>
          <div class="chat ch1">
            <img class='icon' src={profile_img} alt="profile_img" />
            <div class="textbox">
              <QuestionText>
                {processedResponse}
              </QuestionText>
            </div>
          </div>
          <div class="chat ch1">
            <img class='icon' src={profile_img} alt="profile_img" />
            <div class="textbox">
              <QuestionText>
                친구들에게도 꿈해몽 서비스를 공유해 보세요! 😊
              </QuestionText>
            </div>
          </div>
        </div>
      );
    }


  }

  const showNotice = () => {
    Swal.fire({
      icon: 'info',
      title: '답변 관련 Notice',
      html: '꿈해몽 바이블 + ChatGPT를 이용한 꿈 해설은 인터넷 데이터를 검색 및 가공하므로<br>개발자 의도와 상관 없이 폭력/선정적 내용이 포함될 수 있습니다.',
      type: 'info',
      confirmButtonText: '네, 이해했습니다',
      allowOutsideClick: false,  // 팝업창 외 클릭 Disabled
      allowEscapeKey: false,

    });
  }

  const showNoticeCopyURL = () => {
    Swal.fire({
      icon: 'success',
      title: 'URL 복사완료!',
      type: 'info',
      confirmButtonText: 'OK',
    });
  }

  const showShareButton = () => {
    if (isChatGPTresponse.current) {
      // if (isTranslatedResponse.current){
      return (
        <div>
          <GridContainer>
            <FacebookShareButton url={shareURL}>
              <FacebookIcon size={48} round={true} borderRadius={24}></FacebookIcon>
            </FacebookShareButton>
            <FacebookMessengerShareButton url={shareURL}>
              <FacebookMessengerIcon size={48} round={true} borderRadius={24}></FacebookMessengerIcon>
            </FacebookMessengerShareButton>
            {/* <TwitterShareButton url={currentUrl}>
                   <TwitterIcon size={48} round={true} borderRadius={24}></TwitterIcon>
                 </TwitterShareButton> */}
            <CopyToClipboard text={shareURL}>
              <URLShareButton onClick={() => { showNoticeCopyURL() }}>URL</URLShareButton>
            </CopyToClipboard>
            {/* 수정필요 : responseData 그대로 들어가면 kakao share button 동작안할거 */}
            <KakaoShareBtn data={{ data: chatGPTresponse, UUID: UUID }} />
            {/* <KakaoShareBtn data={{ data: chatGPTresponse, pageID: 'resultPage' }} /> */}
          </GridContainer>
        </div>
      )
    }
  }

  const navigate = useNavigate();

  const showKatalkPlus = () => {
    const channelId = '_txjDHxj';
    window.open(`https://pf.kakao.com/${channelId}/chat`);
  };

  return (
    <div style={{}}>
      <Wrapper>
        <Title>꿈풀이 결과</Title>
        <div class="wrap">
          {showResponse()}
        </div>
        <br />
        <h5 style={{ textAlign: "center" }} onClick={showNotice}><font color="grey" size="2">[답변 관련 Notice]</font></h5>
        <br/><br/>
        {showFeedback()}
        <h5 style={{ textAlign: "center" }} onClick={() => { window.open('http://www.freepik.com/'); }}><font color="grey" size="2">Emoji 출처 : Freepik</font></h5>
        <br/>
        
        <FlexContainer>
          <Button style={{ marginRight: '10px' }} className="btn btn-primary btn-lg" onClick={() => { navigate('/'); }}> 다른 꿈풀이 하기 </Button>
          <br /><br/>
          <h5><font color="#FFFFFF">공유하기</font></h5>
          <br />
          {showShareButton()}
        </FlexContainer>
        <br />

        
        <Adfit height={100} width={320} unit={"4P8irfLMA1yEX385"} scrFlag={true} position={"buttom"}></Adfit>
        <br/><br/>
        <TestContainer>
          <h4><font color="#FFFFFF">👇 다른 테스트하러 가기 👇</font></h4><br />
          <img src={TestPREimg} width={230} height={60} onClick={() => { window.open('https://www.previouslife.click/'); }} /><br />
          <img src={TestMZOimg} width={230} height={60} onClick={() => { window.open('https://www.mzofficetest.click/'); }} /><br />
          <img src={TestRRSimg} width={230} height={60} onClick={() => { window.open('https://advancer-rrs.click/'); }} /><br />
          <img src={TestNYRimg} width={230} height={60} onClick={() => { window.open('https://www.newyearrabbit.click/'); }} /><br />
          <img src={TestKTAimg} width={230} height={60} onClick={() => { window.open('https://www.advancer-katalk.click/'); }} /><br />

          <Button style={{ color: 'white', backgroundColor: "rgba(246,194,68)", border: 'None' }}
            onClick={showKatalkPlus}>개발자에게 제안하기</Button>
        </TestContainer>

        <Footer></Footer>
      </Wrapper>
    </div>
  );
};

export default Result;

const FooterWrapper = styled.div`
  font-size: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  color: white;
  text-align: center
`

const QImage = styled.div`
  text-align: center;
`
const Wrapper = styled.div`
  //height: 100vh;
  height: 100%;
  width: 100%;
  font-family: "CookieRun_Regular";
  background: linear-gradient(to bottom, black, #16004d);
  text-align: center
  white-space: pre-line;
  `
const Bar = styled.div`
  padding-top: 2rem;
`
const QuestionNo = styled.div`
  margin: 2rem 1rem 1rem 1rem;
	font-size: 20px;
	text-align: center;
  font-weight: 500;
  color: white;
`
const Title = styled.div`
  padding: 1rem 1rem 1rem 1rem;
	font-size: 17px;
	text-align: center;
  color: white;
  white-space: pre-wrap;
  font-family: "CookieRun_Regular";
`
const TitleSub = styled.div`
  padding: 1rem 1rem 1rem 1rem;
  font-size: 17px;
  text-align: center;
  color: white;
  white-space: pre-wrap;
  font-family: "CookieRun_Regular";
  `

const ButtonGroup = styled.div`
	display: flex;
	flex-direction : column;
	align-items: center;
	justify-content: center;
  white-space: pre-wrap;
  font-family: "CookieRun_Regular";
`

const QuestionText = styled.div`
  padding: 1rem 1rem 1rem 1rem;
  font-size: 15px;
  text-align: left;
  color: black;
  white-space: pre-wrap;
  font-family: "CookieRun_Regular";
  `
// 제목과 버튼을 감싸는 컨테이너
const FlexContainer = styled.div`
  margin-top: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;
// 버튼을 배치시키는 컨테이너
const GridContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 48px);
	grid-column-gap: 8px;
	justify-content: center;
	align-items: center;
	margin-bottom: 16px;
`;
const URLShareButton = styled.button`
	width: 48px;
	height: 48px;
	color: white;
	border-radius: 24px;
	border: 0px;
	font-weight: 800;
	font-size: 18px;
	cursor: pointer;
	background-color: #7362ff;
	&:hover {
		background-color: #a99fee;
	}
`;

const TestContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
`

const FeedbackContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`
