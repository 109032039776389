import React from 'react';
import Button from 'react-bootstrap/Button';
import POSTER from '../assets/main_image2.png';
import kakaobtn from '../assets/kakao.png';
import styled from 'styled-components';
import profile_img from '../assets/main_image.png';

const { Kakao } = window;

const KakaoShareBtn = ({ data }) => {
	const url = "https://dreambook.click/";
	let ShareURL, ShareDesc, ShareImg, ShareTitle;

	if (data.pageID == 'startPage') {
		ShareURL = url;
		ShareDesc = "꿈풀이 말하는대로 검색 서비스";
		ShareImg = 'https://dreambook.click' + profile_img;
		ShareTitle = '로또 사도 되는건가?';

	} else {
		ShareURL = url + 'result/' + data.UUID;
		// ShareDesc = data.data;
		ShareDesc = '생각나는대로 모두 입력하면 상세한 꿈해몽을 무료로 제공합니다!';
		ShareImg = 'https://dreambook.click' + profile_img;
		ShareTitle = '친구 결과보고 해몽 해보기';
	}

	React.useEffect(() => {
		Kakao.cleanup();
		Kakao.init("41567b664d58633030f533cd9bdf02aa");
	}, []);

	const shareKakao = () => {
		Kakao.Share.sendDefault({
			// window.Kakao.Link.sendDefault({
			objectType: 'feed',
			content: {
				title: '꿈풀이 말하는대로 검색 서비스',
				imageUrl: encodeURI(ShareImg),
				link: {
					mobileWebUrl: ShareURL,
					webUrl: ShareURL,
				},
				description: ShareDesc,
			},
			social: {
				likeCount: 9876,
				sharedCount: 51088,
				viewCount: 102072,
			},
			buttons: [
				{
					title: ShareTitle,
					link: {
						mobileWebUrl: ShareURL,
						webUrl: ShareURL,
					},
				},
			]
		});
	}

	return (
		<KakaoSharedButt onClick={shareKakao}>
			<KakaoIcon src={kakaobtn}></KakaoIcon>
		</KakaoSharedButt>
	);
}

export default KakaoShareBtn;

const KakaoSharedButt = styled.a`
cursor:pointer;
`;

const KakaoIcon = styled.img`
width:50px;
height:50px;
border-radius:24px
`;