import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import mainimage from '../assets/main_image.png';
import mainimageGIF from '../assets/mainimage_gif.gif';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import Footer from './footer';
import axios from 'axios';
import Adfit from "./Adfit";
import KakaoShareBtn from '../component/KakaoShareBtn';
import ReactDOM from 'react-dom'
import Swal from "sweetalert2";
import {
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import loading_img from '../assets/loading.gif';

let accUserNum = 0;
document.cookie = "myCookie=test; SameSite=None; Secure";
const Home = () => {
  const navigate = useNavigate();

  // const API_URL = 'https://vjt159l0hb.execute-api.ap-northeast-2.amazonaws.com/prod/dreambook'
  const API_URL = 'https://vjt159l0hb.execute-api.ap-northeast-2.amazonaws.com/prod_feedback/dreambook'
  const TR_API_URL = 'https://vjt159l0hb.execute-api.ap-northeast-2.amazonaws.com/prod_feedback/dreambook_translate'
  const currentUrl = window.location.href;
  const [text, setText] = useState('');
  const isChatGPTrequest = useRef(false);
  const [userAnswerInput, setuserAnswerInput] = useState("");

  // 사용자 답변 저장
  const handleChange = ({ target: { value } }) => {
    setuserAnswerInput(value);
  };


  // 엔터 버튼 클릭 && 내용 포함 시만 함수 동작
  const handleKeyDown = (event) => {
    if (event.key == 'Enter') {
      const inputValue = event.target.value;
      const trimmedInputValue = inputValue.trim();
      if (trimmedInputValue.length !== 0) {
        handleSubmit();
      } else {
        Swal.fire({
          icon: 'warning',
          title: '내용을 입력하세요!',
          confirmButtonText: '알겠습니다',
          allowOutsideClick: false,  // 팝업창 외 클릭 Disabled
          allowEscapeKey: false,
        });
        // 텍스트 입력창 초기화
        setuserAnswerInput("");
      }

    }
  }

  // '꿈풀이 듣기' 클릭 시 로직
  const handleSubmit = () => {
    console.log("제출하기 버튼 클릭");
    try {

      //------------[팝업] ChatGPT 답변 대기 요청------------//
      Swal.fire({
        title: "🔎 꿈풀이 중",
        html: "AI가 꿈 내용을 분석하고 있어요🔍 <br><br>\
                최대 30초까지 소요될 수 있어요! <br>\
                잠시만 기다려주세요 :)",
        allowOutsideClick: false,  // 팝업창 외 클릭 Disabled
        allowEscapeKey: false,
        imageUrl: loading_img,
        imageWidth: 100,
        imageHeight: 100,
        showConfirmButton: false,
      });
      //-----------------------------------------------//
      console.log(`Transfered Data from Client : ${userAnswerInput}`);
      axios
        .post(API_URL, userAnswerInput)
        .then((response) => {
          console.log(`Returned Data : ${response.data}`);
          console.log(`response.data.uuid : ${response.data.uuid}`);
          console.log(`response.data.chatGPTresponse : ${response.data.chatGPTresponse}`);
          Swal.close();
          Swal.fire({
            title: '📌 공지',
            html: "안정적인 서버 운영비 조달을 위해<br> \
                      쿠팡 광고를 삽입했어요😅<br><br> \
                      쿠팡 광고 뜨면 놀라지 마시고<br> \
                      뒤로가기 누르시면 결과 확인하실 수 있어요🙏",
            icon: 'info',
            confirmButtonText: '쿠팡 광고보고 꿈풀이 보기',
            allowOutsideClick: false,  // 팝업창 외 클릭 Disabled
            allowEscapeKey: false,
          }).then(() => {
            window.open('https://link.coupang.com/a/8S6Pb', '_blank');
            navigate(`/result/${response.data.uuid}`);
          });
        })
        // 에러 발생 시 에러 팝업 표시
        .catch((error) => {
          console.log(`error: ${error}`);
          // 대기 팝업 닫기
          Swal.close();
          Swal.fire({
            title: '오류 발생',
            text: `에러 메시지: ${error.message}`,
            icon: 'error',
            confirmButtonText: '다시 해보기',
            allowOutsideClick: false,  // 팝업창 외 클릭 Disabled
            allowEscapeKey: false,

          }).then(() => {
            // 에러 발생 시 강제 새로고침 시키기
            window.location.reload();
          });
        });
      console.log(`AWS Lambda로 텍스트 전송!`);
    } catch (error) {
      console.log(`error: ${error}`);
    }

  }


  const countCharacters = (text) => {
    // 글자 수 카운팅 로직
    return text.length;
  };

  const showUserNum = () => {
    return '5만 명+';
  };

  const showNoticeCopyURL = () => {
    Swal.fire({
      icon: 'success',
      title: 'URL 복사완료!',
      type: 'info',
      confirmButtonText: 'OK',
    });
  };

  const showKatalkPlus = () => {
    const channelId = '_txjDHxj';
    window.open(`https://pf.kakao.com/${channelId}/chat`);
  };

  const resData = [
    {
      id: 1000,
      token: 'a42b7064df',
      name: '****',
      best: '200',
      desc: '****',
      image: mainimage,
      r_iamge: mainimage,
    }];

    const showTextInputBox = () => {

      if (!isChatGPTrequest.current) {
        isChatGPTrequest.current = true
        try {
  
          //------------[팝업] ChatGPT 답변 대기 요청------------//
          Swal.fire({
            title: "🔎 꿈 해석중",
            html: "AI가 당신의 꿈을 해석 중입니다🔥 <br><br>\
                  최대 30초까지 소요될 수 있어요! <br>\
                  잠시만 기다려주세요 :)",
          allowOutsideClick: false,  // 팝업창 외 클릭 Disabled
          allowEscapeKey: false,
          imageUrl: loading_img,
          imageWidth: 100,
          imageHeight: 100,
          showConfirmButton: false,
        });
        //-----------------------------------------------//
        console.log(`Transfered Data from Client : ${text}`);
        axios
          .post(API_URL, text)
          .then((response) => {
            console.log(`Returned Data : ${response.data}`);
            console.log(`response.data.uuid : ${response.data.uuid}`);
            Swal.close();
            Swal.fire({
              title: '📌 공지',
              html: "안정적인 서버 운영비 조달을 위해<br> \
                        쿠팡 광고를 삽입했어요😅<br><br> \
                        쿠팡 광고 뜨면 놀라지 마시고<br> \
                        뒤로가기 누르시면 결과 확인하실 수 있어요🙏",
              icon: 'info',
              confirmButtonText: '쿠팡 광고보고 꿈해몽 확인하기',
              allowOutsideClick: false,  // 팝업창 외 클릭 Disabled
              allowEscapeKey: false,
            }).then(() => {
              window.open('https://link.coupang.com/a/WBPtE', '_blank');
              navigate(`/result/${response.data.uuid}`);

            });
          })
          // 에러 발생 시 에러 팝업 표시
          .catch((error) => {
            console.log(`error: ${error}`);
            // 대기 팝업 닫기
            Swal.close();
            Swal.fire({
              title: '오류 발생',
              text: `에러 메시지: ${error.message}`,
              icon: 'error',
              confirmButtonText: '다시 해보기',
              allowOutsideClick: false,  // 팝업창 외 클릭 Disabled
              allowEscapeKey: false,

            }).then(() => {
              // 에러 발생 시 강제 새로고침 시키기
              window.location.reload();
            });
          });
        console.log(`AWS Lambda로 텍스트 전송!`);
      } catch (error) {
        console.log(`error: ${error}`);
      }
    }
  }

  return (
    <div style={{}}>
      {/* <Snowfall snowflakeCount={50} wind={[0.5, 2.0]} /> */}
      <Wrapper>
        <Header>꿈풀이 말하는대로 검색</Header><br />

        <Desc>어떤 꿈이든 그대로 적어주시면 </Desc>
        <Desc>딱맞는 꿈풀이를 AI가 찾아줍니다.</Desc>

        <Contents>
          <br />
          <LogoImage>
            <img alt='mainpage_img' src={mainimageGIF} width={300} height={300} className="rounded-circle" />
          </LogoImage>

          <Title>꿈 내용을 들려주세요!</Title>
          <br />
          <TextAreaContainer>
            <TextArea type="text" placeholder="이곳에 입력해 주세요 :)"
              required
              value={userAnswerInput}
              onChange={handleChange}
              onKeyPress={handleKeyDown}
            />
            <CharCount>글자수: {countCharacters(userAnswerInput)}</CharCount>
          </TextAreaContainer>
          <br />
          <DreamInputButton
            onClick={() => { handleSubmit() }}
          >
            꿈풀이 확인하기
          </DreamInputButton>
          <FlexContainer>
            <Desc>공유하기</Desc>
            <GridContainer>
              <FacebookShareButton url={currentUrl}>
                <FacebookIcon size={48} round={true} borderRadius={24}></FacebookIcon>
              </FacebookShareButton>
              <FacebookMessengerShareButton url={currentUrl}>
                <FacebookMessengerIcon size={48} round={true} borderRadius={24}></FacebookMessengerIcon>
              </FacebookMessengerShareButton>
              <CopyToClipboard text={currentUrl}>
                <URLShareButton onClick={() => { showNoticeCopyURL() }}>URL</URLShareButton>
              </CopyToClipboard>
              <KakaoShareBtn data={{ data: resData, pageID: 'startPage' }} />
            </GridContainer>
          </FlexContainer>


        </Contents>
        <Adfit height={100} width={320} unit={"5UtJaufqENJkS7XQ"} scrFlag={true} position={"buttom"}></Adfit>
        {/* <Adfit height={50} width={320} unit={"8aakjosDTic30Zmc"} scrFlag={true} position={"middle"}></Adfit> */}
        <STitle>누적 테스트 수 : {showUserNum()}</STitle>
        <br></br>
        <Button style={{ color: 'black', backgroundColor: "rgba(246,194,68)", border: 'None' }}
          onClick={showKatalkPlus}>개발자에게 제안하기</Button>
        <Footer> </Footer>
      </Wrapper>
    </div>
  );
}

export default Home;

const Wrapper = styled.div`
  // height: 100vh;
  height: 100%;
  width: 100%;
  font-family: "CookieRun_Regular";
  background: linear-gradient(to bottom, black, #16004d);
  text-align: center;
  white-space: pre-line;
`
const Header = styled.div`
  padding-top: 2rem;
  font-size: 27pt;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "CookieRun_Regular";
  font-weight: 500;
  color: white;
  text-align: center
`
const Contents = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Title = styled.div`
  font-size: 25px;
  margin-top: 40px;
  color: white;
`

const STitle = styled.div`
  font-size: 17px;
  color: white;
`

const LogoImage = styled.div`
  margin-top: 20px;
`
const Desc = styled.div`
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: white;
`

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`

// 제목과 버튼을 감싸는 컨테이너
const FlexContainer = styled.div`
  margin-top: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

// 버튼을 배치시키는 컨테이너
const GridContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 48px);
	grid-column-gap: 8px;
	justify-content: center;
	align-items: center;
	margin-bottom: 16px;
`;

const URLShareButton = styled.button`
	width: 48px;
	height: 48px;
	color: white;
	border-radius: 24px;
	border: 0px;
	font-weight: 800;
	font-size: 18px;
	cursor: pointer;
	background-color: #7362ff;
	&:hover {
		background-color: #a99fee;
	}
`;

const TextArea = styled.textarea`
  padding: 5px;
  margin-right: 5px; /* 입력창과 버튼 사이에 간격을 주기 위한 margin */
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
  font-size: 16px;
  width: 350px; /* 가로 길이를 400px로 설정하여 좀 더 넓은 입력창 생성 */
  height: 200px; /* 세로로 5줄 이상 텍스트가 써지도록 높이 조정 */
`;

const DreamInputButton = styled.button`
  padding: 10px;
  background-color: #ffcd00; /* 카카오톡 "전송" 버튼과 비슷한 색상 */
  color: #000; /* 버튼 텍스트 색상 */
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  width: calc(100% / 5 * 3 * 3/4);  /* 입력창의 너비에 대한 비율로 버튼의 너비 조정 */
`;


// 컨테이너 스타일드 컴포넌트 추가
const ContainerDreamInput = styled.div`
  display: flex;
  align-items: center;
`;

// 글자수 표시 스타일드 컴포넌트
const CharCount = styled.div`
  position: absolute;
  bottom: 5px;
  left: 10px; /* 왼쪽 여백을 조정하여 텍스트가 좀 더 왼쪽에 위치하도록 함 */
  color: #666; /* 회색 텍스트 색상 */
  font-size: 14px;
`;

const TextAreaContainer = styled.div`
  position: relative;
  flex: 1; /* 입력창과 버튼을 동일한 크기로 설정 */
  left:3px;
  right:3px;
  // margin-right: 10px; /* 입력창과 버튼 사이에 간격을 주기 위한 margin */
  // margin-left: 10px; /* 입력창과 버튼 사이에 간격을 주기 위한 margin */

`;